import React, { useState } from 'react';
import { TextFieldFormsy } from 'src/Spider/components/FormsyComponents/TextFieldFormsy';
import { useIntl } from 'react-intl';
import { RewardAllocationConfigurationType } from '@src/Spider/enums/rewardAllocationConfigurationType';
import { useDecimalHelper } from '@src/Spider/hooks/useDecimalHelper';

const ConfigurationField = ({
  configuration,
  configurationDefaultValue = '',
  onConfigurationChange,
}) => {
  const intl = useIntl();

  const name = configuration.code.toLowerCase();

  const { convertDecimalToText, convertTextWithComma } = useDecimalHelper();
  const initialConfigurationValue =
    configuration.type === RewardAllocationConfigurationType.DECIMAL
      ? convertDecimalToText(configurationDefaultValue)
      : configurationDefaultValue;
  const [configurationValue, setConfigurationValue] = useState(
    initialConfigurationValue,
  );

  const configurationValueChanged = e => {
    e.preventDefault();
    let value = '';
    switch (configuration.type) {
      case RewardAllocationConfigurationType.INTEGER:
        if (e.target.value !== '') {
          value = parseInt(e.target.value);
        }
        break;
      case RewardAllocationConfigurationType.DECIMAL:
        value = convertTextWithComma(
          e.target.value,
          2,
          initialConfigurationValue,
        );
        break;
      default:
        value = e.target.value;
        break;
    }
    setConfigurationValue(value);
    onConfigurationChange({ code: configuration.code, value });
  };

  const getValidation = () => {
    let validations = {};
    switch (configuration.type) {
      case RewardAllocationConfigurationType.INTEGER:
        validations = {
          isInt: true,
          isPositive: true,
        };
        break;
      case RewardAllocationConfigurationType.DECIMAL:
        validations = {
          isNumeric: true,
          isPositive: true,
        };
        break;
      default:
        break;
    }

    return validations;
  };

  const getValidationErrors = () => {
    let validationsErrors = {};
    switch (configuration.type) {
      case RewardAllocationConfigurationType.INTEGER:
        validationsErrors = {
          isInt: intl.formatMessage({
            id: 'common.form.is_int_error',
          }),
          isPositive: intl.formatMessage({
            id: 'common.form.is_more_than_or_equals_0_error',
          }),
        };
        break;
      case RewardAllocationConfigurationType.DECIMAL:
        validationsErrors = {
          isNumeric: intl.formatMessage({
            id: 'common.form.numeric_error',
          }),
          isPositive: intl.formatMessage({
            id: 'common.form.is_more_than_or_equals_0_error',
          }),
        };
        break;
      default:
        break;
    }

    return {
      isDefaultRequiredValue: intl.formatMessage({
        id: 'common.form.required_error',
      }),
      ...validationsErrors,
    };
  };

  const getType = () => {
    let type;
    switch (configuration.type) {
      case RewardAllocationConfigurationType.INTEGER:
        type = 'number';
        break;
      default:
        type = 'text';
        break;
    }

    return type;
  };

  const getStep = () => {
    let step;
    switch (configuration.type) {
      default:
        step = 'any';
        break;
    }

    return step;
  };

  const StartAdornment = () => {
    let startAdornment;
    switch (configuration.code) {
      default:
        startAdornment = null;
        break;
    }
    return startAdornment;
  };

  return (
    <TextFieldFormsy
      name={name}
      value={configurationValue}
      label={intl.formatMessage({
        id:
          'spider.hierarchy_node.platform_customization.parameters.rewards.fieldCodes.' +
          name,
      })}
      variant={'outlined'}
      InputProps={{
        startAdornment: <StartAdornment />,
      }}
      type={getType()}
      required={configuration.required}
      inputProps={{
        min: getType() === 'number' ? '0' : undefined,
        step: getStep(),
      }}
      validations={getValidation()}
      validationErrors={getValidationErrors()}
      onChange={configurationValueChanged}
    />
  );
};

export default ConfigurationField;
