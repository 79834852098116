import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Link } from '@material-ui/core';
import { SystemFileCodes, UserRoles } from '@spider:src/enums';
import { withStyles } from '@mui/styles';
import { useAuth } from '@src/auth';
import { createTheme } from '@material-ui/core/styles';
import { useLinkOpener } from '@src/Spider/hooks/useLinkOpener';
import { useGetHierarchyNodeSystemImagesListQuery } from '@async-calls/systemImages';
import { SystemFileStatuses } from '@src/Spider/enums/systemFileStatuses';
import { useParams } from 'react-router-dom';
import { Loader } from '@src/components';
import { systemColors, themeColors } from '@src/Spider';

const styles = {
  legalDocumentList: {
    display: 'flex',
    alignItems: 'center',
    paddingInlineStart: '0 !important',
    flexDirection: 'row',
    listStyleType: 'disc',
    gap: '0',
    '& li': {
      listStylePosition: 'inside',
      marginLeft: '14px',
    },
    [createTheme().breakpoints.down('md')]: {
      flexDirection: 'column',
      listStyleType: 'none',
      gap: '0.5rem',
      '& li': {
        listStylePosition: 'none',
        marginLeft: '0px',
      },
    },
  },
  '@global': {
    '#ot-sdk-btn.ot-sdk-show-settings.linkWrapper': {
      color: themeColors.secondaryRegular,
      '&:hover': {
        color: systemColors.infoDark,
        backgroundColor: 'transparent',
      },
      backgroundColor: 'transparent',
      padding: 0,
      fontSize: 'inherit',
      textDecoration: 'underline',
    },
  },
};

const Footer = ({ oneTrustKey, ...props }) => {
  const intl = useIntl();
  const { hierarchyNodeUser } = useAuth();
  const { contract } = useParams();

  const { openLink } = useLinkOpener();

  const systemFilesResult = useGetHierarchyNodeSystemImagesListQuery({
    hierarchyNodeId: contract,
    codes: [
      SystemFileCodes.RGPD,
      SystemFileCodes.LEGAL_MENTIONS,
      SystemFileCodes.REGLEMENT,
    ],
    statuses: [SystemFileStatuses.ACTIVE, SystemFileStatuses.INACTIVE],
  });

  const openLinkByCode = documentCode => {
    const documentLink = systemFilesResult?.data?.results?.find(
      document => document.code === documentCode,
    );

    openLink(documentLink?.src);
  };

  useEffect(() => {
    // const t = window.location.hostname;
    // if (t === 'localhost' || t.includes('preprod')) {
    //   if (process.env.NODE_ENV === 'development') {
    //     console.info('Not running in prod: OneTrust not initialized');
    //   }
    //   return;
    // }

    if (oneTrustKey) {
      const script = document.createElement('script');
      script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      script.type = 'text/javascript';
      script.charset = 'UTF-8';
      script.setAttribute('data-domain-script', oneTrustKey);

      const handleError = () => {
        console.error('Erreur lors du chargement du script OneTrust');
        script.remove();
      };

      script.onload = () => {
        if (process.env.NODE_ENV === 'development') {
          console.log('OneTrust script loaded.');
        }
      };

      script.onerror = handleError;
      document.head.appendChild(script);

      const optanonWrapper = document.createElement('script');
      optanonWrapper.type = 'text/javascript';
      optanonWrapper.innerHTML = 'function OptanonWrapper() { }';
      document.head.appendChild(optanonWrapper);

      return () => {
        if (script.parentNode) {
          script.parentNode.removeChild(script);
        }
        if (optanonWrapper.parentNode) {
          optanonWrapper.parentNode.removeChild(optanonWrapper);
        }
      };
    }
  }, [oneTrustKey]);

  return (
    <>
      {systemFilesResult.isFetching && <Loader centered />}
      {!systemFilesResult.isFetching && (
        <footer>
          <div className={'footer-content'}>
            <ul className={props.classes.legalDocumentList}>
              <li style={{ listStyleType: 'none' }}>
                {/* TODO: le n° de version */}
                {intl.formatMessage({ id: 'spider.app_name' })} V1.0.0
              </li>
              <li key={'general-condition'}>
                <Link className={'bold small'} onClick={() => openLink('')}>
                  {intl.formatMessage({
                    id: 'spider.footer.generalUseConditions',
                  })}
                </Link>
              </li>
              <li key={'rgpd'}>
                <Link
                  className={'bold small'}
                  onClick={() => openLinkByCode(SystemFileCodes.RGPD)}
                >
                  {intl.formatMessage({ id: 'spider.footer.dataProtection' })}
                </Link>
              </li>
              <li key={'legal-mention'}>
                <Link
                  className={'bold small'}
                  onClick={() => openLinkByCode(SystemFileCodes.LEGAL_MENTIONS)}
                >
                  {intl.formatMessage({ id: 'spider.footer.legalMentions' })}
                </Link>
              </li>
              {hierarchyNodeUser.role === UserRoles.COLLABORATOR && (
                <li key={'platform-regulation'}>
                  <Link
                    className={'bold small'}
                    onClick={() => openLinkByCode(SystemFileCodes.REGLEMENT)}
                  >
                    {intl.formatMessage({
                      id: 'spider.footer.platformRegulation',
                    })}
                  </Link>
                </li>
              )}
              {oneTrustKey && (
                <li key={'cookies'}>
                  <Link
                    id='ot-sdk-btn'
                    className={`ot-sdk-show-settings bold small linkWrapper`}
                  >
                    {intl.formatMessage({ id: 'spider.footer.cookies' })}
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </footer>
      )}
    </>
  );
};

export default withStyles(styles)(Footer);
